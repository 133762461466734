// @mui
import { useState } from "react";
import {
  Grid,
  Container,
  Typography,
  Box,
  styled,
  TextField,
  Button,
} from "@mui/material";
import moment from "moment";
// components
import Page from "src/components/Page";
import Iconify from "src/components/Iconify";
// sections
import { useMutation, useQuery } from "react-query";
import QUERY_KEYS from "src/_mock/queryKeys";
import appsApi from "src/api/api";
import BasicButton from "src/components/commonblock/BasicButton";
import { LoadingButton } from "@mui/lab";
import palette from "src/theme/palette";
import { store } from "src/redux/store/store";
import { DataGrid } from "@mui/x-data-grid";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { write, utils, writeFile } from "xlsx";

// ----------------------------------------------------------------------

export default function DashboardAdmin() {
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [reportLoading, setReportLoading] = useState(false);
  const { data } = useQuery({
    queryKey: [QUERY_KEYS.ADMIN_DASHBOARD_DATA],
    queryFn: () =>
      appsApi.getAdminDashboard({
        startDate: "30/10/2023",
        endDate: endDate.format("DD/MM/YYYY"),
      }),
  });

  let { jivepayApiTransaction = [], totalAmount = 0 } = data?.data?.data || {
    jivepayApiTransaction: [],
    totalAmount: 0,
  };

  const getReport = useMutation(appsApi.getAdminDashboard, {
    onSuccess: (result) => {
      const { jivepayApiTransaction = [] } = result?.data?.data || {
        jivepayApiTransaction: [],
        totalAmount: 0,
      };
      setReportLoading(false);

      // create a new workbook
      const wb = utils.book_new();
      // create a new sheet from JSON data
      console.log(jivepayApiTransaction);
      const ws = utils.json_to_sheet(jivepayApiTransaction);

      // add the worksheet to the workbook
      utils.book_append_sheet(wb, ws, "Sheet1");

      // generate a dynamic filename based on the current date and time
      const date = new Date();
      const filename = `report_${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}.csv`;

      // write workbook to a CSV file
      writeFile(wb, filename);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const hookGetReport = async () => {
    getReport.mutate({
      startDate: startDate.format("DD/MM/YYYY"),
      endDate: endDate.format("DD/MM/YYYY"),
    });
  };

  const Style = {
    btnHeader: {
      display: "flex",
      justifyContent: "space-between",
      mb: 5,
    },
    buttonStyle: {
      fontWeight: "700",
      minWidth: "180px",
      textTransform: "uppercase",
      fontSize: "13px",
      borderRadius: 0,
      backgroundColor: palette.commonColor.dashboardBtn,
    },
    title: {
      fontSize: "20px",
    },
  };

  const Item = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.commonColor.orangeLightShade,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    height: 150,
    // paddingLeft: 25,
    padding: 25,
    borderRadius: 8,
    color: theme.palette.common.white,
  }));

  const columns = [
    { field: "_id", headerName: "Transaction ID", width: 250 },
    {
      field: "createdAt",
      headerName: "Date",
      sortable: false,
      width: 250,
      valueGetter: (params) =>
        `${moment(params.row.createdAt).format("DD/MM/YYYY hh:mm:ss a") || ""}`,
    },
    {
      field: "userid.name",
      headerName: "User Name",
      width: 250,
      valueGetter: (params) =>
        params.row.userid ? `${params.row.userid.name || ""}` : "",
    },
    {
      field: "userid.number",
      headerName: "CRN",
      width: 250,
      valueGetter: (params) =>
        params.row.userid ? `${params.row.userid.number || ""}` : "",
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 250,
    },
  ];

  // add print css
  const printStyle = `
  @media print {
    @page {
      size: landscape;
    }
    .sidebar-custom {
      display: none !important;
    }
  }
  `;

  const printStyleElement = document.createElement("style");
  printStyleElement.appendChild(document.createTextNode(printStyle));
  document.head.appendChild(printStyleElement);

  // create a tag with href as mailto and click it
  const mailto = document.createElement("a");
  mailto.href =
    "https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=DmwnWstqwgmZXrZWDJsgjFPGKlFjrfhVDlNDzRMTJZwNjmvRZnTXXWQHfDfCpvgdDgPnWvwksXkg";
  mailto.style.display = "none";
  mailto.target = "_blank";
  mailto.rel = "noopener noreferrer";
  mailto.textContent = "Report Issue";
  document.body.appendChild(mailto);
  return (
    <Page title="Dashboard" className={"test123"}>
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} lg={4}>
            <Item>
              <Typography style={Style.title}>
                Total Transactions Amount:
              </Typography>
              <Typography variant="h4">{totalAmount}</Typography>
            </Item>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Item>
              {/* add a refresh button and print button */}
              <LoadingButton
                fullWidth
                size="medium"
                type="submit"
                variant="contained"
                loading={false}
                onClick={() => {
                  window.print();
                }}
              >
                Print Page
              </LoadingButton>

              <LoadingButton
                fullWidth
                size="medium"
                type="submit"
                variant="contained"
                loading={false}
                onClick={() => {
                  window.location.reload();
                }}
                style={{ marginTop: "10px" }}
              >
                Refresh
              </LoadingButton>
              <LoadingButton
                fullWidth
                size="medium"
                type="submit"
                variant="contained"
                loading={false}
                onClick={() => {
                  mailto.click();
                }}
                style={{ marginTop: "10px" }}
              >
                Report Issue
              </LoadingButton>
            </Item>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Item>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                style={{ display: "flex" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Start date"
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                      format="DD/MM/YYYY"
                    />
                  </DemoContainer>
                  <Typography variant="h4">to</Typography>

                  <DemoContainer components={["DatePicker1"]}>
                    <DatePicker
                      label="End date"
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                      format="DD/MM/YYYY"
                    />
                  </DemoContainer>
                </div>
              </LocalizationProvider>
              <LoadingButton
                fullWidth
                size="medium"
                type="submit"
                variant="contained"
                loading={reportLoading}
                onClick={() => {
                  setReportLoading(true);
                  hookGetReport();
                }}
              >
                Get Report
              </LoadingButton>
            </Item>
          </Grid>
          {/* add here container title */}
          <Grid item xs={12} md={12} lg={12} style={{ marginTop: "50px" }}>
            <Box sx={Style.btnHeader}>
              <Typography variant="h4" color={"#ff8a65"}>
                Transactions
              </Typography>
            </Box>
          </Grid>

          <div style={{ height: 550, width: "100%" }}>
            <DataGrid
              rows={jivepayApiTransaction}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 25 },
                },
              }}
              pageSizeOptions={[25, 50, 100]}
              getRowId={(row) => row._id}
            />
          </div>
        </Grid>
      </Container>
    </Page>
  );
}
