import PropTypes from "prop-types";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  BottomNavigation,
  Container,
  Paper,
} from "@mui/material";

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#272e33",
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  // backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

DashboardFootbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

const Style = {
  btnHeader: {
    display: "flex",
    justifyContent: "space-between",
    margin: "15px",
  },
  buttonStyle: {
    fontWeight: "700",
    minWidth: "180px",
    textTransform: "uppercase",
    fontSize: "13px",
    borderRadius: 0.5,
    backgroundColor: "#3f51b5",
  },
};
export default function DashboardFootbar() {
  return (
    <Paper
      sx={{
        marginTop: "calc(10% + 60px)",
        position: "fixed",
        bottom: 0,
        width: "100%",
        backgroundColor: "#272e33",
        boxShadow: "none",
        backdropFilter: "blur(6px)",
        WebkitBackdropFilter: "blur(6px)",
      }}
      component="footer"
      square
      variant="outlined"
    ></Paper>
  );
}
