import { Navigate, useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
// layouts
import DashboardLayout from "src/layouts/dashboard";
import LogoOnlyLayout from "src/layouts/LogoOnlyLayout";
// Pages
import NotFound from "./pages/Page404";
// import Login from "./pages/agent/Login";
import DashboardAdmin from "./pages/admin/DashboardAdmin";
import JivpayDashboard from "./pages/admin/JivepayDashboard";
import Orders from "./pages/admin/Orders";
import Revenue from "./pages/admin/Revenue";
import AdminLogin from "./pages/admin/Login";

const publicRoutes = [
  {
    path: "/",
    element: <LogoOnlyLayout />,
    children: [
      { path: "/", element: <Navigate to="/login" /> },
      { path: "login", element: <AdminLogin /> },
      { path: "404", element: <NotFound /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

const adminProtectedRoutes = [
  { path: "/", element: <Navigate to="/dashboard" /> },
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "/dashboard",
        element: <DashboardAdmin />,
      },
    ],
  },

  { path: "404", element: <NotFound /> },
  { path: "*", element: <Navigate to="/404" /> },
];

export default function Router() {
  const { authToken, userData } = useSelector((state) => state.reducer);
  if (userData && authToken) {
    return useRoutes(adminProtectedRoutes);
  }
  return useRoutes(publicRoutes);
}
