// component
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <Iconify icon={name} width={22} height={22} style={{ color: "white" }} />
);

const navConfig = [
  {
    title: "dashboard",
    path: "/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
  },
  // {
  //   title: "EsiPay",
  //   path: "/esipay",
  //   icon: getIcon("ant-design:pay-circle-outlined"),
  // },
  // {
  //   title: "Credit",
  //   path: "/credit",
  //   icon: getIcon("eva:credit-card-fill"),
  // },
  // {
  //   title: "Flex",
  //   path: "/flex",
  //   icon: getIcon("material-symbols:flex-wrap-rounded"),
  // },
  // {
  //   title: "Ticket",
  //   path: "/ticket",
  //   icon: getIcon("majesticons:ticket-check-line"),
  // },
  // {
  //   title: "Card",
  //   path: "/card",
  //   icon: getIcon("material-symbols:card-travel-outline"),
  // },
  // {
  //   title: "Top Jiver user Balance",
  //   path: "/topJiverUser",
  //   icon: getIcon("material-symbols:account-balance-wallet"),
  // },
  // {
  //   title: "Recent",
  //   path: "/recent",
  //   icon: getIcon("material-symbols:tab-recent-outline-sharp"),
  // },
];

export const adminNavconfig = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
  },
  // {
  //   title: "Jivepay Dashboard",
  //   path: "/admin/jivepaydashboard",
  //   icon: getIcon("ant-design:pay-circle-outlined"),
  // },
  // {
  //   title: "Orders",
  //   path: "/admin/orders",
  //   icon: getIcon("eva:credit-card-fill"),
  // },
  // {
  //   title: "Revenue",
  //   path: "/admin/revenue",
  //   icon: getIcon("material-symbols:card-travel-outline"),
  // },
];
export default navConfig;
