import Axios from "axios";
import _ from "lodash-es";
import { store } from "src/redux/store/store";
import { notiRef } from "src/hooks/useNotify";
import actions from "src/redux/actions/actions";

// export const API_BASE_URL = "http://localhost:8080/";
export const API_BASE_URL = "http://66.70.160.241:8080/";
export const API_FLEX_BASE_URL = "http://66.70.160.243:8080/";

// admin endpoints

const getAdminDashboard = "jivepay-wallet/dashboard-data-v3";
const adminLogin = "jivepay-wallet-user-login";

class AppsApi {
  axios;
  getApi;
  postApi;
  postFormDataApi;
  patchApi;
  deleteApi;
  baseUrl;
  handleError;
  handleResponse;
  constructor(baseUrl) {
    this.baseUrl = baseUrl;

    this.axios = Axios.create({
      baseURL: API_BASE_URL,
      // timeout: 15000,
      validateStatus: (status) => true,
    });
    this.makeFlexAxios = Axios.create({
      baseURL: API_FLEX_BASE_URL,
      timeout: 15000,
      validateStatus: (status) => true,
    });

    this.handleResponse = (response, url, data) => {
      const res = response.data;
      if (response.status === 200 && response?.status) {
        return res;
      }
      if (response.status === 401) {
        store.dispatch(actions.logout());
        notiRef?.current?.toastError(
          res.message || "Oops! You are Unauthorized!",
        );
        return;
      }
      if (res.message && Array.isArray(res.message)) {
        notiRef?.current?.toastError(
          res.message[0] || "Oops! Something went wrong!",
        );
        return;
      }
      if (res.message && typeof res.message === "string") {
        notiRef?.current?.toastError(res.message);
        return;
      }
      throw res;
    };

    this.handleError = (e) => {
      console.error(e);
      let toastMessage = "Something went wrong!";
      if (e.code === "ECONNABORTED") {
        toastMessage =
          "Request timeout! please check your internet connection.";
      }

      notiRef?.current?.toastError(toastMessage);
    };

    this.initSetup();
  }

  initSetup = () => {
    this.getApi = (url) => {
      const token = store ? store.getState().reducer.authToken : "";
      if (token) {
        this.axios.defaults.headers.Authorization = `Bearer ${token}`;
      }
      return this.axios
        .get(url)
        .then((res) => this.handleResponse(res, url))
        .catch(this.handleError);
    };

    this.postApi = (url, data) => {
      const token = store ? store.getState().reducer.authToken : "";
      if (token) {
        this.axios.defaults.headers.Authorization = `Bearer ${token}`;
      }
      const axiosConfig = {};

      return this.axios
        .post(url, data, axiosConfig)
        .then((res) => this.handleResponse(res, url, data))
        .catch(this.handleError);
    };

    this.patchApi = (url, data) => {
      const token = store ? store.getState().reducer.authToken : "";
      if (token) {
        this.axios.defaults.headers.Authorization = `Bearer ${token}`;
      }
      return this.axios
        .patch(url, data)
        .then((res) => this.handleResponse(res, url, data))
        .catch(this.handleError);
    };

    this.deleteApi = (url) => {
      const token = store ? store.getState().reducer.authToken : "";
      if (token) {
        this.axios.defaults.headers.Authorization = `Bearer ${token}`;
      }
      return this.axios
        .delete(url)
        .then((res) => this.handleResponse(res, url))
        .catch((e) => console.error(e));
    };
  };

  // All Endpoints
  getAdminDashboard = (params) => this.postApi(getAdminDashboard, params);
  adminLogin = (params) => this.postApi(adminLogin, params);
}

const appsApi = new AppsApi(API_BASE_URL);
export default appsApi;
