import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";

const theme = createTheme({
  palette: {
    buttonColor: {
      main: "#1e6990",
    },
  },
});
const ContentStyle = styled("div")(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  marginTop: theme.spacing(3),
}));

export default function BasicButton({ loading, title, onClick }) {
  return (
    <ContentStyle>
      <ThemeProvider theme={theme}>
        {/* <Button color="buttonColor" variant="contained">Enter</Button> */}
        <LoadingButton
          // fullWidth
          size="medium"
          type="submit"
          variant="contained"
          sx={{ fontWeight: "700", minWidth: "180px", margin: "10px" }}
          loading={loading}
          onClick={onClick && onClick}
        >
          {title}
        </LoadingButton>
      </ThemeProvider>
    </ContentStyle>
  );
}
